import React from "react";

import Box from "../../../shared/Box";
import Container from "../../../shared/Container";
import Headline from "../../../shared/Headline";
import imageSchool from "./school.svg";
import imageSoccer from "./soccer.jpg";
import * as styles from "./style.module.scss";

const SoccerIndividualTraining = () => {
    return (
        <>
            <Container>
                <Box>
                    <Box.Image format="full" path={imageSoccer} />
                    <Box.Content>
                        <div>
                            <Headline is="1">
                                WERDER Fußballschule - Individualtraining
                            </Headline>

                            <img
                                className={styles.school}
                                src={imageSchool}
                                alt="Schule"
                            />
                            <p>
                                Ihr seid zwischen 6 und 14 Jahren alt und
                                fußballbegeistert? Hier könnt Ihr einen Platz im
                                Individualtraining der WERDER Fußballschule
                                gewinnen. Das Training findet am 06.05.2021 und
                                am 01.06.2021 in der Bolzerei in Bremen
                                (Konsul-Smidt-Straße 18a) zwischen 14:00 und
                                19:15 Uhr statt. In diesem Zeitraum gibt es pro
                                Tag fünf Timeslots, in denen fünf Kinder
                                gleichzeitig trainieren können. Das
                                Individualtraining beinhaltet eine 45-minütige
                                Trainingseinheit unter professioneller Anleitung
                                sowie aktuelle Trikots mit Hosen und Stutzen der
                                WERDER Fußballschule.
                            </p>

                            <Headline is="2">Voraussichtlicher Ablauf</Headline>

                            <ul className={styles.list}>
                                <li>
                                    Anreise und Abholung der Teilnehmenden
                                    erfolgt durch die
                                    Eltern/Erziehungsberechtigten. Die
                                    Teilnehmenden erscheinen umgezogen und mit
                                    Mund-Nasen-Schutz am Treffpunkt – am
                                    Treppenaufgang der Bolzerei. Dort wird ein
                                    Desinfektionsspender platziert. Die
                                    Desinfektion der Hände ist für Teilnehmende
                                    und Trainer verpflichtend. Die
                                    Eltern/Erziehungsberechtigten holen ihre
                                    Kids pünktlich nach Beendigung der
                                    Trainingseinheit am Parkplatz der Bolzerei
                                    ab. Der Parkplatz ist kostenfrei nutzbar.{" "}
                                </li>
                                <li>
                                    Die Trainer führen die Teilnehmenden jeweils
                                    einzeln direkt über die Außentreppe zum
                                    separaten Trainingsplatz, wo sich die
                                    Teilnehmenden auf die Einheit vorbereiten
                                    (u.a. Schuhe). Tasche und/oder
                                    Überziehkleidung der Teilnehmenden sind
                                    während des Trainings in einem der Tore des
                                    Platzes gelagert.{" "}
                                </li>
                                <li>
                                    Während des Trainings ist kein
                                    Trainerwechsel vorgesehen! Das Training
                                    findet ohne Teilnahme von Zuschauern,
                                    Eltern, etc. statt.{" "}
                                </li>
                                <li>
                                    Die Begleitperson erhält vorab einen
                                    Gutschein für einen Hoyer-Mehrwegbecher samt
                                    Kaffeespezialität per Mail, der beim Hoyer
                                    Tank-Treff Hansator (nur 10 Minuten vom
                                    Veranstaltungsort entfernt) eingelöst werden
                                    kann, um die Wartezeit zu versüßen.{" "}
                                </li>
                                <li>
                                    Anschließend führen die Trainer ihre
                                    Teilnehmenden jeweils einzeln über den
                                    Treppenaufgang zurück zum Parkplatz (vorher
                                    Handdesinfektion in der Bolzerei,
                                    Desinfektionsspender am Ausgang), wo die
                                    Abholenden die Teilnehmenden in Empfang
                                    nehmen.{" "}
                                </li>
                            </ul>

                            <Headline is="2">Teilnahmebedingungen</Headline>
                            <Headline is="3">1. Allgemeines </Headline>
                            <p>
                                Der Veranstalter des Gewinnspiels ist die
                                Wilhelm Hoyer B.V. &amp; Co.KG (nachfolgend
                                Hoyer Unternehmensgruppe), Rudolf-Diesel-Str. 1,
                                27374 Visselhövede. Das Gewinnspiel steht in
                                keiner Verbindung zu Facebook und Instagram und
                                wird in keiner Weise von Facebook oder Instagram
                                gesponsert, unterstützt oder organisiert. Mit
                                der Teilnahme am Gewinnspiel akzeptiert der
                                Teilnehmer diese Teilnahmebedingungen.
                            </p>
                            <Headline is="3">2. Aktionszeitraum </Headline>
                            <p>
                                Eine Teilnahme am Gewinnspiel ist nur online im
                                Aktionszeitraum vom 14.04.2021 bis 19.04.2021,
                                23:59 Uhr möglich. Alle späteren Teilnahmen, die
                                nach dem Zeitpunkt erfolgen, werden nicht mehr
                                berücksichtigt.
                            </p>
                            <Headline is="3">
                                3. Teilnahmeberechtigung{" "}
                            </Headline>
                            <p>
                                Die Teilnahme am Gewinnspiel ist bis auf etwaige
                                Internetverbindungskosten kostenlos und
                                unabhängig von der Inanspruchnahme einer
                                Dienstleistung. Teilnahmeberechtigt am
                                Gewinnspiel sind Personen, die das 18.
                                Lebensjahr vollendet und ihren Wohnsitz in
                                Deutschland haben. Mitarbeiter und Angehörige
                                der Hoyer Unternehmensgruppe dürfen nicht am
                                Gewinnspiel teilnehmen und werden von der
                                Teilnahme ausgeschlossen. Die Teilnahme von
                                Gewinnspielagenturen und sonstigen automatisch
                                generierten Massenteilnahmen und -zusendungen
                                wird ausdrücklich ausgeschlossen und bei der
                                Ermittlung der Gewinner nicht berücksichtigt.
                                <Headline is="4">
                                    3.1 Bedingungen für die Einlösung des
                                    Gewinns
                                </Headline>
                                Eine Einlösung des Gewinns und damit die
                                Teilnahme am Individualtraining ist nur möglich,
                                wenn
                                <ul className={styles.list}>
                                    <li>
                                        das Hygienekonzept für
                                        Indoor-/Outdoorevents innerhalb der
                                        Liegenschaften des SV Werder Bremen GmbH
                                        &amp; Co KG aA und sämtliche daraus
                                        resultierende Regelungen akzeptiert und
                                        erfüllt werden. Das Konzept ist hier zu
                                        finden.
                                    </li>
                                    <li>
                                        das für die Teilnahme am
                                        Individualtraining angemeldete Kind die
                                        Altersvorgaben (sechs bis 14 Jahre)
                                        erfüllt.
                                    </li>
                                    <li>
                                        der erforderliche Anmeldeprozess bei der
                                        WERDER Fußballschule vollständig
                                        ausgeführt wurde.
                                    </li>
                                </ul>
                            </p>
                            <Headline is="3">4. Nutzungsbedingungen </Headline>
                            <p>
                                Die Teilnahme am Gewinnspiel erfolgt durch zwei
                                Schritte:
                            </p>
                            <p>
                                1. Den Beitrag auf Facebook oder Instagram
                                liken. <br />
                                2. Bis zum 19.04.2021, 23:59 Uhr unter dem
                                Beitrag kommentieren, warum das Kind beim
                                Individualtraining dabei sein soll.
                            </p>
                            <p>
                                Unter allen Teilnehmern werden die Plätze für
                                das Individualtraining der WERDER Fußballschule
                                verlost. Die Gewinner werden per Direktnachricht
                                in dem jeweiligen sozialen Netzwerk (Instagram
                                oder Facebook) benachrichtigt. Jeder Teilnehmer
                                ist für die Richtigkeit, der von ihm an die
                                Hoyer Unternehmensgruppe übermittelten Daten
                                selbst verantwortlich. Sofern die
                                benachrichtigten Gewinner nicht innerhalb von
                                zwei Tagen mit der Hoyer Unternehmensgruppe per
                                Direktnachricht in dem jeweiligen sozialen
                                Netzwerk (Instagram oder Facebook) Kontakt
                                aufnehmen und die Teilnahme bestätigen, werden
                                neue Kandidaten ausgewählt. Nach der Vergabe der
                                Timeslots durch Hoyer Unternehmensgruppe (soweit
                                möglich in Abstimmung mit den Gewinnern) werden
                                die Kontaktdaten an die WERDER Fußballschule
                                übergeben. Die Gewinner erhalten einen Link zur
                                kostenfreien Online-Registrierung (inklusive
                                Zustimmung zu den Datenschutzbestimmungen und
                                Abgabe des ausgefüllten Gesundheitsfragebogens).
                                Eventuell entstehende
                                Anreise-/Übernachtungskosten müssen von den
                                Gewinnern selbst getragen werden. Gewinne können
                                nicht in bar ausgezahlt werden und sind nicht
                                übertragbar. Die Teilnahme an diesem Gewinnspiel
                                verpflichtet nicht zum Kauf bei der Hoyer
                                Unternehmensgruppe. Käufe bei der Hoyer
                                Unternehmensgruppe haben keinen Einfluss auf die
                                Gewinnmöglichkeit. Die Trainingsslots werden
                                ausgelost. Es besteht kein Anspruch auf Auswahl
                                einer Trainingszeit/eines Trainingstages. Der
                                Rechtsweg ist ausgeschlossen.
                            </p>
                            <Headline is="3">
                                5. Vorzeitige Beendigung des Gewinnspiels{" "}
                            </Headline>
                            <p>
                                Die Hoyer Unternehmensgruppe behält sich vor,
                                das Gewinnspiel zu jedem Zeitpunkt ohne
                                Vorankündigung zu beenden. Von dieser
                                Möglichkeit macht die Hoyer Unternehmensgruppe
                                insbesondere dann Gebrauch, wenn aus technischen
                                Gründen (z.B. Viren in Computersystemen,
                                Manipulation oder Fehler in der Hard-/Software)
                                oder aus rechtlichen Gründen eine ordnungsgemäße
                                Durchführung des Gewinnspiels nicht
                                gewährleistet werden kann.
                            </p>
                            <Headline is="3">6. Haftung </Headline>
                            <p>
                                Hoyer Unternehmensgruppe haftet insbesondere
                                nicht für Schäden, die durch Fehler,
                                Verzögerungen oder Unterbrechung in der
                                Übermittlung, bei Störungen der technischen
                                Anlagen und des Services, unrichtige Inhalte,
                                Verlust oder Löschung von Daten, Viren oder in
                                sonstiger Weise bei der Teilnahme an
                                Gewinnspielen entstehen können, es sei denn,
                                dass solche Schäden von Hoyer Unternehmensgruppe
                                (deren Organen, Mitarbeitern oder
                                Erfüllungsgehilfen) vorsätzlich oder grob
                                fahrlässig herbeigeführt werden.
                            </p>
                            <Headline is="3">
                                7. Versicherung des Teilnehmers{" "}
                            </Headline>
                            <p>
                                Der Teilnehmer versichert, dass er sämtliche
                                Rechte (insbesondere Urheber- und
                                Persönlichkeitsrechte) an seinem Profilfoto zur
                                Veröffentlichung bei Facebook und Instagram (und
                                damit weltweit im Internet) besitzt und er
                                keinen fremden Namen als Facebook oder Instagram
                                User-Namen verwendet. Er stellt die Hoyer
                                Unternehmensgruppe in Bezug auf derartige
                                Ansprüche von Dritten frei.
                            </p>
                            <Headline is="3">8. Datenschutz </Headline>
                            <p>
                                Die Gewinner werden per Direktnachricht bei
                                Facebook und Instagram informiert. Für die
                                Gewinnermittlung werden die folgenden Daten
                                erhoben: Name, Anschrift, Telefonnummer,
                                E-Mail-Adresse, Konfektionsgröße des Kindes,
                                Geburtstag des Kindes, unterschriebene
                                Datenschutzerklärung. Jeder Gewinner muss diese
                                Daten über eine private Direktnachricht an die
                                Hoyer Unternehmensgruppe Facebook-Fanpage oder
                                Instagramseite mitteilen. Alle Facebook- und
                                Instagram-Namen der Teilnehmer werden erfasst
                                und zur Gewinnermittlung gespeichert. Die Daten
                                werden spätestens 30 Tage nach Ende des
                                Gewinnspiels gelöscht. Das gilt nicht für die
                                Kommentare auf Facebook und Instagram. Diese
                                bleiben bestehen und sind weiterhin öffentlich
                                sichtbar. Hoyer Unternehmensgruppe verarbeitet
                                die personenbezogenen Daten, wenn nicht explizit
                                anders angegeben, nur zum Zwecke der Ziehung
                                sowie zur Benachrichtigung des Gewinners.
                                Rechtsgrundlage ist die Einwilligung des
                                Teilnehmers in die Datenschutzerklärung, Art. 6
                                Abs. 1 lit. a) DS-GVO. Die Gewinnerdaten werden
                                an Dritte (in diesem Fall an die SV Werder
                                Bremen GmbH & Co KG Aa) weitergegeben, um die
                                dortige Anmeldung samt Anerkenntnis der
                                Hygienevorschriften und der
                                Datenschutzrichtlinien in die Wege zu leiten.
                                Die weitere Verarbeitung der Daten durch die SV
                                Werder Bremen GmbH & Co KG Aa fällt unter die
                                Verantwortung der SV Werder Bremen GmbH & Co KG
                                Aa. Bei der Hoyer Unternehmensgruppe werden die
                                personenbezogenen Daten, die im Zuge der
                                Teilnahme an diesem Gewinnspiel gespeichert
                                wurden, wenn nicht ausdrücklich anders
                                gewünscht, nach Ablauf der gesetzlichen
                                Speicherfristen gelöscht. Sie sind gemäß Art. 15
                                DS-GVO jederzeit berechtigt, gegenüber Hoyer um
                                Auskunftserteilung zu den zur Person
                                gespeicherten Daten zu ersuchen. Gemäß Art. 16
                                ff. kann der Teilnehmer jederzeit gegenüber
                                Hoyer Unternehmensgruppe die Berichtigung,
                                Löschung und Sperrung einzelner
                                personenbezogener Daten verlangen. Der
                                Teilnehmer kann darüber hinaus jederzeit ohne
                                Angabe von Gründen von seinem Widerspruchsrecht
                                Gebrauch machen und die erteilte
                                Einwilligungserklärung mit Wirkung für die
                                Zukunft abändern oder gänzlich widerrufen. Der
                                Widerruf kann entweder postalisch oder per
                                E-Mail (datenschutz@hoyer.de) an Hoyer
                                Unternehmensgruppe übermittelt werden. Die
                                weitere Teilnahme an dem Gewinnspiel ist damit
                                nicht mehr möglich.
                            </p>
                        </div>
                    </Box.Content>
                </Box>
            </Container>
        </>
    );
};

export default SoccerIndividualTraining;
